$primary: #006839;

@import "~bootstrap/scss/bootstrap";
@import "./form.scss";

body {
  font-family: montserrat,sans-serif;
}

.accordion-button {
  padding: 5px 15px;
}

.rs-picker-toggle-wrapper {
  display: block !important;
}