.reminder {
  .entry {
    border: 1px solid var(--rs-border-primary);
  }

  &.past .entry {
    text-decoration: line-through;
  }

  > p {
    color: #333;
    font-size: 80%;;
  }

  &.reminder-enter {
    opacity: 0;
  }
  &.reminder-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  &.reminder-exit {
    opacity: 1;
  }
  &.reminder-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: rgb(87, 87, 87);
}