.App.dark {
  .PhoneInput {
    background-color: var(--rs-input-bg);
    border-color: #3c3f43;

    &:hover,
    &:has(input:focus-visible) {
      border-color: var(--rs-input-focus-border);
    }

    &:has(input:focus-visible) {
      -webkit-box-shadow: 0 0 0 3px rgb(52, 152, 64);
      -webkit-box-shadow: var(--rs-state-focus-shadow);
      box-shadow: 0 0 0 3px rgb(52, 152, 64);
      box-shadow: var(--rs-state-focus-shadow);
    }

    input {
      background-color: var(--rs-input-bg);
      border-color: var(--rs-input-bg);
      color: var(--rs-text-primary);

      &:focus-visible {
        -webkit-tap-highlight-color: var(--rs-input-bg);
        outline: var(--rs-input-bg);
      }
    }
  }
}