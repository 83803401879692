header {
  border-bottom: 5px solid #006839;

  a {
    text-decoration: none !important;
    
    img {
      height: 7.5rem;
      float: left;
    }

    h1 {
      height: 7.5rem;
      padding-top: 1.875rem;
      font-size: 40px !important;
      line-height: 48px;

      > span {
        display: block;
        font-weight: 200;
        font-size: 80% !important;
      }
    }
  }
}

.rs-navbar-brand {
  .rs-btn {
    display: none;
  }
}

.rs-navbar {
  border-bottom: 3px solid #006839;
  margin-bottom: 15px;
}

footer {
  border-top: 3px solid #006839;
}

h1, h2 {
  color: #006839 !important;
}

@media (max-width: 767.98px) {
  header {
    text-align: center;

    a {
      img {
        float: none;
      }
    }
  }

  .rs-navbar-brand {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 200%;
    font-weight: 600;
    padding: 10px 15px !important;
    position: relative;
    pointer-events: none;

    .rs-btn {
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      bottom: 10px;
      z-index: 10;
      pointer-events: all;
    }
  }

  .rs-navbar-nav {
    .rs-navbar-item {
      display: flex;
      width: 100%;
      align-content: center;
      transition: all linear 0.2s;
    }

    &.closed {
      .rs-navbar-item {
        height: 0;
        padding: 0 18px !important;
      }
    }
  }

  .nav-link,
  .navbar-brand {
    padding: 0 15px;
  }

  .navbar-toggler {
    margin-right: 15px;
  }
}