.error-page {
  font-size: 300%;
  gap: 45px;
  flex-direction: row;

  > *:first-child {
    order: -1;
  }

  &::before {
    content: "";
    border: 0.5px solid #ccc;
    align-self: stretch;
  }

  p {
    margin-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .error-page {
    flex-direction: column;
    row-gap: 10px;
  }
}