$transition: all ease-in 0.2s;

.form-group {
  transition: $transition;
  border-left: 0px solid $danger;

  label {
    transition: $transition;
  }

  .form-text {
    transition: $transition;
  }

  &.error {
    padding-left: 15px;
    border-left: 3px solid $danger;

    label {
      color: $danger;
      font-weight: bold;
    }

    .form-text {
      color: $danger;
    }
  }
}